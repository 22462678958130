var TorrentsTable;

$('[data-toggle="tooltip"]').tooltip();

TorrentsTable = (function() {
  return {
    limit: 25,
    offset: 25,
    loading: false,
    query: null,
    filters: {
      field: 'seeds',
      sort: 'desc',
      time: 0,
      category: 'all',
      adult: false,
      risky: false
    },
    table: $(".table-content"),
    table_header: $("#table-header"),
    template: $(".row-template"),
    loader: $("#loader"),
    loader_error: $(".loader.error"),
    loader_end: $(".loader.end"),
    getCookie: function(name) {
      var matches, result;
      matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
      return result = matches ? decodeURIComponent(matches[1]) : void 0;
    },
    init: function() {
      var query, self;
      query = url('?');
      if (query != null) {
        if (query.time != null) {
          this.filters.time = query.time;
        }
        if (query.category != null) {
          this.filters.category = query.category;
        }
      }
      self = this;
      $('.title', this.table).unbind("mousedown");
      $('.title', this.table).on("mousedown", function(e) {
        e.preventDefault();
        return self.open_details($(this));
      });
      $('.show-comments', this.table).unbind("mousedown");
      $('.show-comments', this.table).on("mousedown", function(e) {
        var id, label, row;
        e.preventDefault();
        row = $(this).parent().parent().parent();
        id = $(".name .id", row).html();
        label = $('.name .title', row).html();
        return Comments.show(id, label);
      });
      $('.sort', this.table_header).unbind("mousedown");
      $('.sort', this.table_header).on("mousedown", function(e) {
        var element, icon, icon_class;
        e.preventDefault();
        element = $(this);
        if (element.hasClass('label')) {
          element = $('.sort.toggle', $(this).parent());
        }
        icon = element.find('i');
        e.preventDefault();
        $('.sort', this.table_header).data('sdir', 'none');
        $('.sort', this.table_header).removeClass('active');
        $('.sort > i', this.table_header).each(function() {
          var _element;
          _element = $(this);
          if (this !== icon[0]) {
            _element.removeClass('fa-sort-asc');
            _element.removeClass('fa-sort-desc');
            return _element.addClass('fa-sort');
          }
        });
        icon_class = icon.attr('class').match(/(fa-sort)(-\w{3,4})?/)[0];
        if (icon_class) {
          if (icon_class === 'fa-sort' || icon_class === 'fa-sort-desc') {
            icon.addClass('fa-sort-asc');
            self.sort_table(element.data('sort'), 'asc');
          }
          if (icon_class === 'fa-sort-asc') {
            icon.addClass('fa-sort-desc');
            self.sort_table(element.data('sort'), 'desc');
          }
        }
        icon.removeClass(icon_class);
        return element.addClass('active');
      });
      this.init_tooltip();
      this.init_unsafe_tooltip();
      $(".scroll-up").on("mousedown", function(e) {
        e.preventDefault();
        return self.table.animate({
          scrollTop: 0
        }, 500, 'swing');
      });
      $(".show-more").on("mousedown", function(e) {
        e.preventDefault();
        return self.load_data();
      });
      this.table.scroll(function() {
        if (self.need_load_data()) {
          return self.load_data();
        }
      });
      this.table.trigger('scroll');
      if (this.table.data('queue-id')) {
        this.add_queue(this.table.data('queue-id'));
      }
      return this;
    },
    sort_table: function(field, dir) {
      if (field == null) {
        field = 'name';
      }
      if (dir == null) {
        dir = 'asc';
      }
      this.filters.field = field;
      this.filters.sort = dir;
      return this.load_data(false);
    },
    search: function(query) {
      this.query = query;
      return this.load_data(false);
    },
    filter_table: function(type, value) {
      if (type == null) {
        type = 'time';
      }
      if (value == null) {
        value = 0;
      }
      console.log(type === 'time' ? value : this.filters.time);
      this.filters = {
        field: this.filters.field,
        sort: this.filters.sort,
        time: type === 'time' ? value : this.filters.time,
        category: type === 'category' ? value : this.filters.category,
        adult: type === 'adult' ? value : this.filters.adult,
        risky: type === 'risky' ? !value : this.filters.risky
      };
      return this.load_data(false);
    },
    open_details: function(element) {
      var details;
      details = element.parent().find("div.details");
      if (details.hasClass("hidden")) {
        element.addClass("active");
        return details.removeClass("hidden");
      } else {
        element.removeClass("active");
        return details.addClass("hidden");
      }
    },
    insert_rows: function(data) {
      var i, item, len, rows;
      for (i = 0, len = data.length; i < len; i++) {
        item = data[i];
        if (item != null) {
          this.loader.before(this.replace_template(item));
        }
      }
      this.loader_error.addClass('hidden');
      this.loader_end.addClass('hidden');
      rows = this.table.find('tr.bls-row');
      if (rows.length < 25) {
        this.loader.addClass('hidden');
        this.loader_end.removeClass('hidden');
      }
      return this.loading = false;
    },
    format_date: function(timestamp) {
      return moment.unix(timestamp).fromNow();
    },
    replace_template: function(item) {
      var _template, href, match, ref, self, source_details;
      self = this;
      _template = this.template.clone();
      source_details = $(".details .labels .label.source a", _template);
      if (item.is_verified) {
        $('.name', _template).removeClass('unsafe');
      } else {
        $('.name', _template).addClass('unsafe');
      }
      $(".name .id", _template).html(item.id);
      if (item.encrypted_id) {
        href = $(".share-button", _template).prop('href');
        match = /share\/(.{3,})/g.exec(href);
        href = href.replace(match[1], item.encrypted_id);
        $(".share-button", _template).prop('href', href);
      }
      $(".name .title", _template).html(this.title_span(item));
      $(".name .title", _template).unbind("mousedown");
      $(".name .title", _template).on("mousedown", function(e) {
        e.preventDefault();
        return self.open_details($(this));
      });
      switch (item.source) {
        case 'ezTv':
          source_details.attr('href', 'https://eztv.ag/');
          source_details.html('EzTV');
          break;
        case 'thePirateBay':
          source_details.attr('href', 'https://thepiratebay.se/');
          source_details.html('The Pirate Bay');
          break;
        case 'kickassTorrents':
          source_details.attr('href', 'https://kickass2.nz/');
          source_details.html('KickAss Torrents');
          break;
        case 'torrentz2':
          source_details.attr('href', 'https://torrentz2.eu/');
          source_details.html('Torrentz2');
          break;
        case 'yts':
          source_details.attr('href', 'https://yts.am/');
          source_details.html('YTS.AM');
      }
      source_details.removeClass('hidden');
      $(".details .labels .label.source", _template).attr("data-source", item.source);
      $(".details .download-btn", _template).attr('href', item.magnet);
      $(".details .labels .label.details a", _template).attr('href', item.link);
      $('.show-comments', _template).unbind("mousedown");
      $('.show-comments', _template).on("mousedown", function(e) {
        e.preventDefault();
        return Comments.show(item.id, item.name);
      });
      $(".stream-button", _template).attr('href', (ref = item.magnet) != null ? ref.replace('magnet', 'torrentstream') : void 0);
      $(".magnet-button", _template).attr('href', item.magnet);
      if (item.tv_show !== null && !$(".show-series", _template).hasClass('not-show')) {
        href = ($(".show-series", _template).attr('href')).replace('0', item.tv_show);
        $(".show-series", _template).removeClass('hidden').attr('href', href);
      }
      if (item.category != null) {
        $(".category", _template).html(item.category.title);
        $(".category", _template).data('index', item.category.id);
      } else {
        $(".category", _template).html('Other');
        $(".category", _template).data('index', 1000);
      }
      $('.category.filter-element').unbind('mousedown');
      $('.category.filter-element').on('mousedown', function(e) {
        e.preventDefault();
        return Filters.filter($(this));
      });
      $(".age", _template).html(this.format_date(item.age));
      $(".size", _template).html(item.size);
      $(".seeds", _template).attr('data-id', item.id).html(item.seeds);
      $(".peers", _template).attr('data-id', item.id).html(item.peers);
      if (item.comments > 0) {
        $(".comments-count", _template).html(item.comments);
      }
      ReportAdult.init($(".report-adult-button", _template));
      ScrapStat.init(_template);
      this.init_tooltip();
      this.init_unsafe_tooltip(_template);
      _template.removeClass('hidden').removeClass('row-template');
      return _template;
    },
    init_unsafe_tooltip: function(container) {
      return $(".unsafe", container).tooltipster({
        content: 'Not verified safe. Download at your own risk.'
      });
    },
    title_span: function(item) {
      return item.name + "<span class='btn-group btn-group-xs'><a class='btn btn-default' href='http://www.mybub.com/search/" + item.name + "' target='_blank' data-toggle='tooltip' data-placement='right' title='Find more info'><i class='fa fa-info' aria-hidden='true'></i></a></span>";
    },
    show_spy_off: function() {
      return false;
    },
    init_tooltip: function() {
      var show_tooltip;
      show_tooltip = function(button) {
        var ipLocation;
        button.tooltipster({
          contentCloning: true,
          multiple: true,
          interactive: true,
          theme: ['tooltipster-noir', 'tooltipster-noir-customized'],
          position: 'right'
        });
        button.tooltipster('open');
        if (button.hasClass('stream-button')) {
          $('.banner-tooltip .button.download').html("<b>Play torrent</b>");
        } else {
          $('.banner-tooltip .button.download').html("<b>Download torrent</b>");
        }
        ipLocation = $('body').data('geo-ip');
        if (ipLocation) {
          $('.banner-tooltip .location').html(ipLocation.city + ", " + ipLocation.region);
          $('.banner-tooltip .location_wrapper').show();
        } else {
          $('.banner-tooltip .location_wrapper').hide();
        }
        $('.banner-tooltip .button.download').unbind('click');
        $('.banner-tooltip .button.download').on("click", function(e) {
          e.preventDefault();
          window.location = button.attr('href');
          return button.tooltipster('destroy');
        });
        $('.banner-tooltip .button.enable').unbind('click');
        $('.banner-tooltip .button.enable').on("click", function(e) {
          e.preventDefault();
          window.open($(this).data('action'), '_blank');
          return button.tooltipster('destroy');
        });
        $('.banner-tooltip .button.cancel').unbind('click');
        $('.banner-tooltip .button.cancel').on("click", function(e) {
          e.preventDefault();
          return button.tooltipster('destroy');
        });
        return $('.banner-tooltip .checkbox').on("change", function(e) {
          var date, now_ts;
          e.preventDefault();
          now_ts = new Date().getTime() / 1000 | 0;
          date = new Date((now_ts + 365 * 24 * 60 * 60) * 1000);
          document.cookie = "spyoff_dnshow=" + now_ts + ";path=/; expires=" + date.toUTCString();
          return $('.tooltipstered').tooltipster('destroy');
        });
      };
      return $(".banner-button").on("click", function(e) {
        e.preventDefault();
        return show_tooltip($(this));
      });
    },
    data_error: function(error) {
      this.loader.addClass('hidden');
      this.loader_error.removeClass('hidden');
      this.loader_end.addClass('hidden');
      console.error(error.status + ": " + error.statusText);
      return this.loading = false;
    },
    load_data: function(shift) {
      var rows, self;
      if (shift == null) {
        shift = true;
      }
      if (!this.loading || !shift) {
        this.loading = true;
        this.loader.removeClass('hidden');
        this.loader_error.addClass('hidden');
        this.loader_end.addClass('hidden');
        self = this;
        if (!shift) {
          rows = this.table.find('tr.bls-row');
          this.offset = 0;
          rows.remove();
        }
        if (this.query === null) {
          this.query = $('input[name="q"]').val();
        }
        return $.ajax({
          url: this.table.data('list'),
          method: 'post',
          beforeSend: function(request) {
            return request.setRequestHeader('X-Request-Token', global_variables.token);
          },
          data: {
            query: self.query,
            offset: self.offset,
            limit: self.limit,
            filters: self.filters
          },
          success: function(data) {
            if ((data == null) || (data != null ? data.error : void 0)) {
              self.data_error({
                status: 500,
                statusText: 'Server error'
              });
              return;
            }
            if ((data != null) && (((data != null ? data.content : void 0) != null) || !shift) && !(data != null ? data.error : void 0)) {
              if ((data != null ? data.offset : void 0) !== self.offset && (data != null ? data.content.length : void 0) > 0) {
                self.insert_rows(data != null ? data.content : void 0);
                if ((data != null ? data.queue_id : void 0) != null) {
                  self.add_queue(data != null ? data.queue_id : void 0);
                }
                if (shift) {
                  self.offset += self.limit;
                } else {
                  self.offset = 25;
                }
                if (TorrentsTable.need_load_data()) {
                  return TorrentsTable.load_data();
                }
              } else {
                self.loader.addClass('hidden');
                return self.loader_end.removeClass('hidden');
              }
            }
          },
          error: function(error) {
            return self.data_error(error);
          }
        });
      }
    },
    need_load_data: function() {
      return this.table.scrollTop() >= this.table[0].scrollHeight - this.table[0].offsetHeight - this.loader[0].offsetHeight;
    },
    add_queue: function(id) {
      var self;
      self = this;
      return setTimeout(function() {
        return $.ajax({
          url: self.table.data('queue').replace('id', id),
          method: 'get',
          success: function(data) {
            var field, i, len, ref, results, torrent;
            if ((data != null ? data.status : void 0) === 'status_done') {
              ref = data != null ? data.torrents : void 0;
              results = [];
              for (i = 0, len = ref.length; i < len; i++) {
                torrent = ref[i];
                results.push((function() {
                  var j, len1, ref1, results1;
                  ref1 = ['seeds', 'peers'];
                  results1 = [];
                  for (j = 0, len1 = ref1.length; j < len1; j++) {
                    field = ref1[j];
                    results1.push($(".rescrap." + field + "[data-id=" + torrent.id + "]", self.table).text(torrent[field]));
                  }
                  return results1;
                })());
              }
              return results;
            } else if ((data != null ? data.status : void 0) === 'status_in_progress') {
              return self.add_queue(id);
            }
          }
        });
      }, 15000);
    }
  };
})();

$(document).ready(function() {
  return TorrentsTable.init();
});
